<template>
  <div class="bg-white shadow-lg rounded-md">
    <picture class="flex">
      <source :srcset="`${opinion.imagen}.webp`" type="image/webp" />
      <img
        :src="`${opinion.imagen}.jpg`"
        alt="Servicio"
        class="aspect-auto object-none md:object-contain w-full"
      />
    </picture>
    <div class="flex flex-col p-3 md:pb-8 md:p-5 h-fit">
      <div class="flex mb-3">
        <StarIcon class="fill-yellow-500 h-5 w-5" v-for="_ in 5" />
      </div>
      <div class="flex items-center gap-x-1 mb-2">
        <span class="font-bold md:text-lg">{{ opinion.nombre }}</span>
        <CheckBadgeIcon class="fill-green-400 h-6 w-6" />
      </div>
      <p class="text-xs md:text-sm text-gray-700">
        {{ opinion.texto }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { StarIcon, CheckBadgeIcon } from "@heroicons/vue/24/solid";
  import type { Opinion } from "@lib/interfaces";
  interface Props {
    opinion: Opinion;
  }
  defineProps<Props>();
</script>
