<template>
  <div class="text-2xl sm:text-3xl md:text-4xl text-center font-bold mb-8">
    + DE 150.000 PEDIDOS ENTREGADOS,
    <span class="text-rose-500">MIRA LO QUE OPINAN DE NOSOTROS</span>
  </div>
  <Flicking
    :options="options"
    :plugins="pluginsCarruselJugosasOpiniones"
    class="lg:hidden"
    ref="carruselJugosasOpiniones"
  >
    <div
      class="flex mb-20 lg:mb-28 gap-x-3 sm:gap-x-6 justify-center h-auto w-full"
      v-for="(agrupado, index) in opinionesAgrupadas"
      :class="{ 'mr-3 sm:mr-6': index != opinionesAgrupadas.length - 1 }"
      :key="index"
    >
      <TarjetaJugosaOpinion :opinion="agrupado[0]" class="w-1/2" />
      <TarjetaJugosaOpinion :opinion="agrupado[1]" class="w-1/2" />
    </div>
    <template #viewport>
      <Paginador
        class="bottom-5"
        :carrusel="carruselJugosasOpiniones"
        :nombre-paginador="nombrePaginadorCarruselJugosasOpiniones"
        :background="false"
      />
    </template>
  </Flicking>
  <div class="hidden lg:flex lg:justify-center gap-x-6">
    <TarjetaJugosaOpinion
      v-for="opinionJugosa in opiniones"
      :opinion="opinionJugosa"
      :class="[`w-1/${opiniones.length}`]"
    />
  </div>
</template>
<script setup lang="ts">
  import Paginador from "@ui/Paginador.vue";
  import TarjetaJugosaOpinion from "@components/TarjetaJugosaOpinion.vue";
  import Flicking from "@egjs/vue3-flicking";
  import { plugins } from "@lib/bullets";
  import "@egjs/flicking-plugins/dist/pagination.css";
  import opiniones from "src/data/jugosas-opiniones.json";
  import { ref, computed } from "@vue/runtime-core";

  interface Props {
    panels?: number;
    class?: string;
  }
  const props = withDefaults(defineProps<Props>(), {
    panels: -1,
  });
  const options = {
    align: "center",
    panelsPerView: props.panels,
    noPanelStyleOverride: false,
    preventDefaultOnDrag: true,
  };

  const opinionesAgrupadas = computed(() => {
    const opinionesAgrupadas = [];
    for (let i = 0; i < opiniones.length; i += 2) {
      opinionesAgrupadas.push([opiniones[i], opiniones[i + 1]]);
    }
    return opinionesAgrupadas;
  });

  const carruselJugosasOpiniones = ref<Flicking>();
  const nombrePaginadorCarruselJugosasOpiniones =
    "paginador-carrusel-jugosas-opiniones";
  const pluginsCarruselJugosasOpiniones = plugins(
    nombrePaginadorCarruselJugosasOpiniones,
    false,
  );
</script>
